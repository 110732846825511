<template>
    <div>
        <div style="overflow-y:auto;margin-bottom:20px;" id="mar_height"  v-if="detaildata.length > 0" >
            <div class="detail_top">
                <div style="word-break: break-all;padding: 5px 0px;"> <span class="orderlable">訂單號：</span>{{ orderData.mainOrderNum }}<br/><span class="orderlable">order number</span></div>
                <div style="word-break: break-all;padding: 5px 0px;"> <span class="orderlable">下單日期：</span>{{ parseTime(orderData.createTime) }}<br/><span class="orderlable">Order date</span></div>
                <div style="word-break: break-all;padding: 5px 0px;"> <span class="orderlable">計劃發貨日期：</span>{{ formatWeek(orderData.sendTime) }}<br/><span class="orderlable">Shipping date</span></div>
                <van-row class="top_line_height" style=" min-height: 30px;line-height: 30px;">
                    <van-col span="8" class="orderlable">共 {{orderData.totalGoods}} 件商品 </van-col>
                </van-row>
                <!-- <div  style="word-break: break-all;padding: 5px 0px;"> <span class="orderlable">訂單支付總金額：</span>￡{{ orderData.dealAmount }}<br/><span class="orderlable">Grand total</span></div>
                <div  style="word-break: break-all;padding: 5px 0px;"> <span class="orderlable">需支付綜金額：</span>￡{{ orderData.actualAmount }}<br/><span class="orderlable">Required payment</span></div>
                <div  style="word-break: break-all;padding: 5px 0px;"> <span class="orderlable">在綫支付金額：</span>￡{{ orderData.onlineAmount }}<br/><span class="orderlable">Online total</span></div>
                <div  style="word-break: break-all;padding: 5px 0px;color:red;"> <span class="orderlable" style="color:red;">貨到付款金額：</span>￡{{ orderData.offlineAmount }}<br/><span class="orderlable" style="color:red;">Offline total</span></div>
                <div :class="'ordertoatl1'" style="display:none;">
                    <van-divider />
                    <van-row v-for="(item,index) in detaildata" :key="index" class="top_line_height" style=" min-height: 30px;line-height: 30px;">
                        <van-col span="24">
                            <van-col span="12" class="orderlable" v-if="index == 0">主訂單金額：</van-col>
                            <van-col span="12" class="orderlable" v-else>{{index}}#補充訂單金額：</van-col>
                            <van-col span="12" class="text-right"> ￡{{ item.dealAmount }} </van-col>
                        </van-col>
                        <van-col span="24">
                            <van-col span="12" class="orderlable" v-if="index == 0">在綫支付金額：</van-col>
                            <van-col span="12" class="orderlable" v-else>{{index}}#在綫支付金額：</van-col>
                            <van-col span="12" class="text-right"> ￡{{ item.onlinePayment }} </van-col>
                        </van-col>
                    </van-row>
                    <van-row class="top_line_height" style=" min-height: 30px;line-height: 30px;">
                        <van-col span="12">訂單支付總金額：</van-col>
                        <van-col span="12" class="text-right"> ￡{{ orderData.dealAmount }} </van-col>
                    </van-row>
                    <van-row class="top_line_height" style=" min-height: 30px;line-height: 30px;">
                        <van-col span="12">在綫支付總金額：</van-col>
                        <van-col span="12" class="text-right"> ￡{{ orderData.onlineAmount }} </van-col>
                    </van-row>
                </div>
                <div style="float:right;color:#1c16ecf5" @click="orderToatl('ordertoatl1')"><span id="ordertoatl1_button">查看详细</span></div>
                <div style="clear:both"></div> -->
            </div>
            <div>
                <van-row class="detail_top">
                    <van-col span="8">送貨信息： </van-col>
                    <van-col span="16" style="word-break: break-all;"> {{ detaildata[0].remark ? '貨到前' +detaildata[0].remark+ '分鐘致電我' : ''}} </van-col>
                </van-row>
            </div>
            <div v-if="orderData.userLuckInfoEntity" class="awardGoods">
                <van-row>
                    <van-col span="10" class="awardname">平臺贈送獎勵</van-col>
                    <van-col span="14" v-if="orderData.userLuckInfoEntity.type == 'commodity'" class="awardname" style="text-align: right">{{orderData.userLuckInfoEntity.name}}<span v-if="orderData.userLuckInfoEntity.prizeNum > 1">x{{orderData.userLuckInfoEntity.prizeNum}}</span> {{orderData.userLuckInfoEntity.showUnit}}</van-col>
                    <van-col span="14" v-else class="awardname voucher" style="text-align: right">錢包獎金￡{{orderData.userLuckInfoEntity.price}}</van-col>
                </van-row>
                <van-row>
                    <van-col span="10" class="awardname" >Free Prize</van-col>
                    <van-col span="14" v-if="orderData.userLuckInfoEntity.type == 'commodity'" class="awardname" style="text-align: right">{{orderData.userLuckInfoEntity.nameEn}}<span v-if="orderData.userLuckInfoEntity.prizeNum > 1">x{{orderData.userLuckInfoEntity.prizeNum}}</span> {{orderData.userLuckInfoEntity.showUnit}}</van-col>
                    <van-col span="14" v-else class="awardname voucher" style="text-align: right">Top Up￡{{orderData.userLuckInfoEntity.price}}</van-col>
                </van-row>
            </div>
            <div v-for="(item,index) in detaildata" :key="index">
                <div style="width:93%;" class="detail_top">
                    <van-row class="order_item">
                        <van-col span="24" v-if="index == 0">主订单</van-col>
                        <van-col span="24" v-else>({{index}})補充訂單</van-col>
                    </van-row>
                    <van-row class="order_item" v-if="index != 0">
                        <van-col span="8">子訂單號:</van-col>
                        <van-col span="16">{{item.subOrderNum}}</van-col>
                    </van-row>
                    <van-row class="order_item" v-if="index != 0">
                        <van-col span="8">下單時間:</van-col>
                        <van-col span="16">{{parseTime(item.createTime)}}</van-col>
                    </van-row>
                    <van-row class="order_item">
                        <van-col span="24"> {{item.totalGoodsNum}} 件商品</van-col>
                    </van-row>
                    <!-- <van-row class="order_item">
                        <van-col span="24">总金额：￡{{item.dealAmount}}</van-col>
                    </van-row>
                    <van-row class="order_item">
                        <van-col span="24">需支付綜金額：￡{{item.actualPayment}}</van-col>
                    </van-row>
                    <van-row class="order_item">
                        <van-col span="24">在綫支付金額：￡{{item.onlinePayment}}</van-col>
                    </van-row> -->

                    <div :class="'ordermain' + index" style="display:none;">
                        <van-divider />
                        <van-tabs :value="'tabActive'+ index ? 'in'+ index : 'out'+ index" :ref="'ordermain' + index" line-width="80px">
                            <van-tab title="商用詳情" :name="'in'+ index" style="margin-top:10px;">
                                <van-row class="foot_line_height">
                                    <van-col span="24" class="orderlable">商用 (For Commercial)</van-col>
                                </van-row>
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">商品總價 (Total)</van-col>
                                    <van-col span="10" class="text-right"><span class="symbol">￡</span>{{item.inAmount}} </van-col>
                                </van-row>
                                <van-row class="foot_line_height" v-if="index == 0">
                                    <van-col span="14" class="orderlable">優惠金額 (Voucher)</van-col>
                                    <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{item.couponIn}}</van-col>
                                </van-row>
                                <!-- <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">錢包抵扣 (Wallet)</van-col>
                                    <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>-{{item.walletIn}}</van-col>
                                </van-row> -->
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">折扣金額 (Discount)</van-col>
                                    <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{item.discountIn}}<span style="font-size:14px"> ({{Math.floor((1-item.discount)*100)}}%off)</span></van-col>
                                </van-row>
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">VAT </van-col>
                                    <van-col span="10" class="text-right "><span class="symbol">￡</span>{{item.vatIn}} </van-col>
                                </van-row>
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">商用需支付金額 (Grand total)</van-col>
                                    <van-col span="10" class="text-right "> <span class="symbol">￡</span>{{item.dealIn}} </van-col>
                                </van-row>
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">支付方式 (Payment method)</van-col>
                                    <van-col span="10" class="text-right " style="white-space:pre"> {{item.paymentMethodIn == '1'? '在綫支付\n Pay Online':'貨到付款\n Pay On Delivery'}} </van-col>
                                </van-row>
                            </van-tab>
                            <van-tab title="自用詳情" :name="'out'+ index" style="margin-top:10px;">
                                <van-row class="foot_line_height">
                                    <van-col span="24" class="orderlable">自用 (For Domestic)</van-col>
                                </van-row>
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">商品總價 (Total)</van-col>
                                    <van-col span="10" class="text-right"><span class="symbol">￡</span>{{item.outAmount}} </van-col>
                                </van-row>
                                <van-row class="foot_line_height" v-if="index == 0">
                                    <van-col span="14" class="orderlable">優惠金額 (Voucher)</van-col>
                                    <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{item.couponOut}}</van-col>
                                </van-row>
                                <!-- <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">錢包抵扣 (Wallet)</van-col>
                                    <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>-{{item.walletOut}}</van-col>
                                </van-row> -->
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">折扣金額 (Discount)</van-col>
                                    <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{item.discountOut}}<span style="font-size:14px"> ({{Math.floor((1-item.discount)*100)}}%off)</span></van-col>
                                </van-row>
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">VAT </van-col>
                                    <van-col span="10" class="text-right "><span class="symbol">￡</span>{{item.vatOut}} </van-col>
                                </van-row>
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">自用需支付金額 (Grand total)</van-col>
                                    <van-col span="10" class="text-right "> <span class="symbol">￡</span>{{item.dealOut}} </van-col>
                                </van-row>
                                <van-row class="foot_line_height">
                                    <van-col span="14" class="orderlable">支付方式 (Payment method)</van-col>
                                    <van-col span="10" class="text-right " style="white-space:pre"> {{item.paymentMethodOut == '1'? '在綫支付\n Pay Online':'貨到付款\n Pay On Delivery'}} </van-col>
                                </van-row>
                            </van-tab>
                        </van-tabs>
                    </div>
                    <div style="float:right;color:#1c16ecf5" @click="orderMain('ordermain' + index)"><span :id="'ordermain' + index + 'button'">查看详细</span></div>
                    <div style="clear:both"></div>
                </div>
                <div :class="'orderShow'+ index + 't'" style="display:block">
                    <v-order :order-data="item.detailList[0]" :configure="configure" :type="true"></v-order>
                </div>
                <div :class="'orderShow'+ index + 'b'" style="display:none">
                    <v-order v-for="(child,childindex) in item.detailList.filter(x =>x.type == 0)" :key="childindex" :order-data="child" :configure="configure" :type="true"></v-order>
                    <div v-if="item.detailList.filter(x =>x.type != 0).length > 0"  style="margin-bottom:5px;">
                        <div class="redeem_main" v-if="item.detailList.filter(x =>x.type == 5).length > 0">
                            <div v-for="(h,index) in item.detailList.filter(x =>x.type == 5)" :key="h.id+index" style="margin-bottom:10px;">
                                <van-row>
                                    <van-col span="10" class="redeem_dom" style="color:#666">{{h.goodsName}}<br>{{h.goodsNameEn}}</van-col>
                                    <van-col span="14" class="redeem_dom" style="text-align: right">x {{h.qty}}</van-col>
                                </van-row>
                            </div>
                        </div>
                        <div class="awardGoods" v-if="item.detailList.filter(x =>x.type == 1).length > 0 || item.detailList.filter(x =>x.type == 2).length > 0">
                            <van-row>
                                <van-col span="10" class="awardname">平臺贈送獎勵</van-col>
                                <van-col span="14" v-if="item.detailList.filter(x =>x.type == 1).length > 0" class="awardname" style="text-align: right">{{item.detailList.filter(x =>x.type == 1)[0].goodsName}}<span v-if="item.detailList.filter(x =>x.type == 1)[0].qty > 1">x{{item.detailList.filter(x =>x.type == 1)[0].qty}}</span> {{item.detailList.filter(x =>x.type == 1)[0].unit}}</van-col>
                                <van-col span="14" v-if="item.detailList.filter(x =>x.type == 2).length > 0" class="awardname voucher" style="text-align: right">錢包獎金￡{{Math.abs(item.detailList.filter(x =>x.type == 2)[0].unitPrice)}}</van-col>
                            </van-row>
                            <van-row>
                                <van-col span="10" class="awardname" >Free Prize</van-col>
                                <van-col span="14" v-if="item.detailList.filter(x =>x.type == 1).length > 0" class="awardname" style="text-align: right">{{item.detailList.filter(x =>x.type == 1)[0].goodsNameEn}}<span v-if="item.detailList.filter(x =>x.type == 1)[0].qty > 1">x{{item.detailList.filter(x =>x.type == 1)[0].qty}}</span> {{item.detailList.filter(x =>x.type == 1)[0].unit}}</van-col>
                                <van-col span="14" v-if="item.detailList.filter(x =>x.type == 2).length > 0"  class="awardname voucher" style="text-align: right">Top Up￡{{ Math.abs(item.detailList.filter(x =>x.type == 2)[0].unitPrice)}}</van-col>
                            </van-row>
                        </div>
                        <div class="awardGoods" v-if="item.detailList.filter(x =>x.type == '3').length > 0">
                            <van-row>
                                <van-col span="10" class="awardname">平臺贈送禮物</van-col>
                                <van-col span="14" class="awardname" style="text-align: right">
                                    {{item.detailList.filter(x =>x.type == '3')[0].goodsName}}
                                    <span v-if="item.detailList.filter(x =>x.type == '3')[0].qty > 1">x{{item.detailList.filter(x =>x.type == '3')[0].qty}}</span>
                                    {{item.detailList.filter(x =>x.type == '3')[0].unit}}
                                </van-col>
                            </van-row>
                            <van-row>
                                <van-col span="10" class="awardname" >Free Gift</van-col>
                                <van-col span="14"  class="awardname" style="text-align: right">
                                    {{item.detailList.filter(x =>x.type == '3')[0].goodsNameEn}}
                                    <span v-if="item.detailList.filter(x =>x.type == '3')[0].qty > 1">x{{item.detailList.filter(x =>x.type == '3')[0].qty}}</span>
                                    {{item.detailList.filter(x =>x.type == '3')[0].unit}}
                                </van-col>
                            </van-row>
                        </div>
                    </div>
                </div>
                <div style="text-align:center;" v-show="item.detailList.length > 1">
                    <!-- <van-button  size="large" style="width:93%;" @click="showMore('orderShow'+ index)"><span :id="'orderShow'+ index + 'button'">Show more</span></van-button> -->
                    <van-button  size="large" style="width:93%;" @click="showMore('orderShow'+ index)" :class="'orderShow'+ index + 'button'">Show more</van-button>
                </div>
            </div>
            <!-- 退貨start -->
            <div v-for="(item,index) in refundList" :key="'r'+ index">
                <div style="width:93%;position:relative" class="detail_top" >
                    <van-row class="order_item">
                        <van-col span="24">{{index+1}}#退款記錄</van-col>
                    </van-row>
                    <van-row class="order_item">
                        <van-col span="8">申請時間:</van-col>
                        <van-col span="16">{{parseTime(item.applyTime)}}</van-col>
                    </van-row>
                    <van-row class="order_item">
                        <van-col span="8">審核時間:</van-col>
                        <van-col span="16">{{parseTime(item.processTime)}}</van-col>
                    </van-row>
                    <van-row class="order_item">
                        <van-col span="8">審核結果:</van-col>
                        <van-col span="16">
                            <span v-if="item.status == 0">申請中</span>
                            <span v-if="item.status == 1">通過</span>
                            <span v-if="item.status == 2">駁回</span>
                            <span v-if="item.status == 3">撤銷</span>
                        </van-col>
                    </van-row>
                    <van-row class="order_item">
                        <van-col span="24"> {{item.goodsNum}} 件商品</van-col>
                    </van-row>
                    <van-row class="order_item">
                        <van-col span="24">退款金额：￡{{item.actualAmount}}</van-col>
                    </van-row>
                    <div style="float:right;color:#1c16ecf5" @click="returnMain('returnmain' + index)"><span :id="'returnmain' + index + 'button'">查看详细</span></div>
                    <div style="clear:both"></div>
                    <div :class="'returnmain'+ index" style="display:none;">
                        <div v-for="main in item.detailList" :key="main.id">
                            <van-card
                                :title="main.goodsName"
                                :desc="main.goodsNameEn"
                                class="goods-card"
                                currency='￡'
                                @click-thumb="changeImg($event,main)"
                            >
                                <template #price>
                                    <div class="card_price">
                                        ￡<span style="font-size:16px;">{{main.unitPrice}}</span>
                                        <span class="vatclass" v-if="main.isVat == '1'"> +VAT</span>
                                    </div>
                                </template>
                                <template #thumb>
                                    <van-image  :src="main.goodsCover" width="100%" height="100%"/>
                                </template>
                                <template #tags>
                                    <span style="font-size:14px;color:#888;margin-top:6px;display: inline-block">規格：{{main.unitPrice}}/{{main.unit}}</span><br/>
                                    <span style="font-size:14px;color:#888;margin-top:6px;margin-bottom:6px;display: inline-block">退貨：{{main.num}}</span>
                                </template>
                            </van-card>
                        </div>
                    </div>
                    <span class="returnStatus" v-if="item.status == 0">待審核</span>
                </div>
            </div>
            <!-- 退貨end-->
            <div style="text-align:center;" class="bottom_button" v-show="detaildata.filter(x =>x.status == 0).length > 0">
                <van-button  size="large" type="info" style="width:45%;" @click="payForOrder(detaildata.filter(x =>x.status == 0))">待支付</van-button>
                <van-button  size="large" style="width:45%;" @click="cencalOrder(detaildata.filter(x =>x.status == 0))">取消訂單</van-button>
            </div>
            <!-- <div  style="text-align:center;" class="bottom_button" v-if="orderType.s == '1' && orderType.t == '0'">
                <van-button  size="large" type="info" style="width:100%;" @click="jumpRefund">退貨</van-button>
            </div> -->
        </div>
    </div>
</template>
<script>
import vOrder from "@/components/order"
import {orderDetail,cencalOrder,getRefundList} from "@/api/order"
export default {
    name:'orserdetail',
    components: {
        vOrder
    },
    data() {
        return {
            detaildata:[],
            configure: {
                time:false,
                title:true,
                columns:[
                    {
                        prop:'unitPrice',
                        label:'單價',
                        disabled: true,
                    },
                    {
                        prop:'qty',
                        label:'數量',
                        disabled: true,
                    },
                    {
                        prop:'vatAmount',
                        label:'VAT',
                        disabled: true,
                    },

                ]
            },
            orderNum:undefined,
            orderData:{},
            detailshow:false,
            grandHeight:0,
            activeNames:['1'],
            refundList:[],
            orderType:{}
        }
    },
    mounted () {

    },
    watch: {
    },
    created() {
        this.orderNum = this.$route.query.id;
        this.orderType.s = this.$route.query.s;
        this.orderType.t = this.$route.query.t;
        this.getDetailorder()
        this.getRefundList()
    },
    methods: {
        getDetailorder() {
            orderDetail(this.orderNum).then(res => {
                this.detaildata = res.data.mallOrderFullVO.orderList;
                this.orderData = res.data.mallOrderFullVO;
            })
        },
        detailPopup() {
            this.detailshow = !this.detailshow ;
        },
        showMore(name) {
            if(document.getElementsByClassName(name + 't')[0].style.display == 'block') {
                document.getElementsByClassName(name + 't')[0].style.display = 'none';
            }else {
                document.getElementsByClassName(name + 't')[0].style.display = 'block';
            }

            if(document.getElementsByClassName(name + 'b')[0].style.display == 'block') {
                document.getElementsByClassName(name + 'b')[0].style.display = 'none';
                // document.getElementById(name + 'button').innerText = 'Show more';
            }else {
                document.getElementsByClassName(name + 'b')[0].style.display = 'block';
                // document.getElementById(name + 'button').innerText = 'Put away';
            }
            document.getElementsByClassName(name + 'button')[0].style.display = 'none';
        },
        orderMain(name) {
            if(document.getElementsByClassName(name)[0].style.display == 'block') {
                document.getElementsByClassName(name)[0].style.display = 'none';
                document.getElementById(name + 'button').innerText = '查看詳細';
            }else {
                document.getElementsByClassName(name)[0].style.display = 'block';
                document.getElementById(name + 'button').innerText = '隐藏详细';
            }
            this.$refs[name][0].resize();
        },
        returnMain(name) {
            if(document.getElementsByClassName(name)[0].style.display == 'block') {
                document.getElementsByClassName(name)[0].style.display = 'none';
                document.getElementById(name + 'button').innerText = '查看詳細';
            }else {
                document.getElementsByClassName(name)[0].style.display = 'block';
                document.getElementById(name + 'button').innerText = '隐藏详细';
            }
        },
        orderToatl(name) {
            if(document.getElementsByClassName(name)[0].style.display == 'block') {
                document.getElementsByClassName(name)[0].style.display = 'none';
                document.getElementById('ordertoatl1_button').innerText = '查看詳細';
            }else {
                document.getElementsByClassName(name)[0].style.display = 'block';
                document.getElementById('ordertoatl1_button').innerText = '隐藏详细';
            }
        },
        cencalOrder(item) {
            cencalOrder({orderNum:item[0].orderNum,subOrderNum:item[0].subOrderNum ? item[0].subOrderNum : item[0].orderNum}).then(res => {
                if(res.code == 200) {
                    this.$emit('cencalorder')
                    this.$router.push('/order')
                }
            })
        },
        payForOrder(item) {
            let orderData = JSON.stringify({subOrderNum:item[0].subOrderNum,paymentMethodIn: item[0].paymentMethodIn,paymentMethodOut: item[0].paymentMethodOut})
            this.$router.push({path:'/cart/result',query:{orderData:orderData}})
        },
        getRefundList() {
            getRefundList({orderNum:this.orderNum}).then(res => {
                if(res.code == 200) {
                    this.refundList = res.data.refundList;
                }
            })
        },
        jumpRefund() {
            this.$router.push({
                path: '/refund',
                query: {
                    orderNum: this.orderNum,
                    s: this.orderType.s,
                    t: this.orderType.t
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.main{
  font-size: 16px;
}
.foot {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #fff;
    // border-top-right-radius:10px;
    // border-top-left-radius:10px;
}
.bottom_detail {
    padding: 8px 40px;
    font-size: 18px;
    height: 100%;
}
.detail_top{
    font-size:15px;
    padding:20px 28px;
    background:#fff;
    width: 93%;
    margin: 15px auto;
    border-radius: 15px;
}
.text-right {
    text-align: right;
}
.foot_line_height {
    min-height: 60px;
    line-height: 60px;
}
.top_line_height {
    // min-height: 60px;
    // line-height: 60px;
}
.detail {
    height: 80px;
    line-height: 50px;
    text-align: center;
    z-index: 3000;
    position:relative;
}
// .foot_line_height {
//     height: 30px;
//     line-height: 30px;
// }
.symbol {
    font-size:14px;
}
.text-green {
    color: #72B950;
}
.text-orange {
    color: #FF8E29;
}
.text-grey {
    color: #919191;
}
.arrow_down {
    background: url('../../assets/image/aw_down.png') no-repeat;
    background-size: 100% 100%;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
}
.arrow_up {
    background: url('../../assets/image/aw_up.png') no-repeat;
    background-size: 100% 100%;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
}
.orderlable{
    color: #666;
}
.order_item {
    height: 50px;
    line-height: 50px;
}
.awardname {
  min-height: 50px;
  line-height: 50px;
  font-size: 14px;
}
.voucher{
  background: url('../../assets/image/bg_ddlj.png') no-repeat;
  background-size: 100% 100%;
}
.awardGoods{
  width: 93%;
  margin: auto;
  border-radius: 15px;
  padding: 0 20px;
  background: #fff;
  margin-top: 15px;
}
.bottom_button {
    display: flex;
    justify-content: space-between;
    width:93%;
    margin: 40px auto;
}
#firstchild :nth-child(2) {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 0px;
}
.category {
  background: #fff;
  margin: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 1px solid #ebedf0;
  margin-top: 15px;
  font-size: 18px;
  /* min-height: 80px;
  line-height: 80px; */
}
.vancard {
    // margin-top: 15px;
    box-shadow: 0 0.02667rem 0.26667rem 0 rgba(0,0,0,.06);
}
.vatclass {
    font-size: 14px;
    color: #F7A92E;
}
.returnStatus {
    color: red;
    position: absolute;
    top: 10px;
    right: 20px;
}
    /deep/ .van-ellipsis {
        text-overflow: initial;
        white-space: initial;
    }
    /deep/ .van-card__desc {
        max-height: initial;
        color: #646566;
        line-height: initial;
    }
    .redeem_dom {
      font-size: 14px;
    }
    .redeem_main {
      width: 93%;
      margin: auto;
      border-radius: 15px;
      padding: 20px 20px 10px;
      background: #fff;
      margin-top: 15px;
    }
</style>
