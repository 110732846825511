import request from '@/utils/request'

// 查询订单列表
export function orderList(data) {
    return request({
      url: '/app/mallorder/list',
      method: 'post',
      data: data
    })
}

//查询订单详情
export function orderDetail(orderNum) {
  return request({
    url: '/app/mallorder/detail/' + orderNum,
    method: 'get'
  })
}

//取消订单
export function cencalOrder(data) {
  return request({
    url: '/app/mallorder/cancelOrder',
    method: 'post',
    data:data
  })
}

//获取一个订单退款的记录（for mobile customer）
export function getRefundList(data) {
  return request({
    url: '/app/refund/list',
    method: 'post',
    data:data
  })
}

//設置訂單狀態
export function setOrderStatus(data) {
  return request({
    url: '/app/mallorder/setOrderPayingStatus',
    method: 'post',
    data:data
  })
}

//獲取訂單狀態
export function getOrderStatus(data) {
  return request({
    url: '/app/mallorder/getOrderStatus',
    method: 'post',
    data:data
  })
}

//查詢批號
export function batchNumber(detailId) {
  return request({
    url: '/app/mallorder/getBatch/' + detailId,
    method: 'get'
  })
}

//删除订单
export function delOrder(data) {
  return request({
    url: '/app/mallorder/deleteOrder',
    method: 'post',
    data:data
  })
}
