<template>
    <div class="main">
            <van-row  type="flex" v-if="type"  class="mainrow">
                <van-col span="24">
                    <van-card
                        :title="orderData.goodsName"
                        :desc="orderData.goodsNameEn"
                        class="goods-card"
                        currency='￡'
                        @click-thumb="changeImg($event,orderData)"
                    >   
                        <template #price>
                            <div class="card_price">
                                ￡<span style="font-size:16px;">{{orderData.unitPrice}}</span>
                                <span class="vatclass" v-if="orderData.vat == '1'"> +VAT</span>
                            </div>
                        </template>
                        <template #thumb>
                            <van-image  :src="orderData.goodsCover" width="100%" height="100%"/>
                        </template>
                        <template #tags>
                            <span style="font-size:14px;color:#888">規格：{{orderData.unit}}</span><br/>
                        </template>
                        <template #num>
                            <span>x {{orderData.qty}}</span> <br/>
                            <span>For Commerial x {{orderData.qtyIn}}</span> <br/>
                            <span>For Domestic x {{orderData.qtyOut}}</span> 
                        </template>
                        <template #footer>
                            <van-button plain type="primary" @click="batchNumber(orderData)" size="mini" style="border-radius:5px;padding:2px 10px;width:50px;height:25px;margin-top:5px;">批號</van-button>
                        </template>
                    </van-card>
                </van-col>
            </van-row>
            <van-row  type="flex" v-if="!type"  class="main-row">
                <van-col span="24" style="line-height:30px;" @click="oredrDetail(orderData)">
                    <div v-if="configure.time" class="ordercode" style="word-break: break-all;"> 訂單號：{{ orderData.orderNum }}<br/>order number</div>
                    <div class="ordercode" style="word-break: break-all;"> 下單日期：{{ parseTime(orderData.createTime) }}<br/>Order date</div>
                    <div class="ordercode" style="word-break: break-all;"> 計劃發貨日期：{{ formatWeek(orderData.sendTime) }}<br/>Shipping date</div>
                    <div class="line"></div>
                    <van-grid  :border="false" :column-num="4" style="max-height:85px;overflow: hidden;">
                        <van-grid-item v-for="(t,index) in orderData.detailList" :key="index">
                            <van-image :src="t.goodsCover" width="50" height="50"/>
                        </van-grid-item>
                    </van-grid>
                    <van-row style="font-size:14px;text-align:right">
                        <!-- <van-col span="8">共計{{orderData.totalGoodsNum}}件商品</van-col>
                        <van-col span="16">
                            <van-col span="24">
                                訂單總金額:<span>(Grand total)</span><br>
                                <span>
                                    <span style="font-size:12px;">￡</span>
                                    <span>{{orderData.dealAmount}}</span>
                                </span>
                            </van-col>
                            <van-col span="24">
                                需支付綜金額:<span>(Required payment)</span>
                                <span>
                                    <span style="font-size:12px;">￡</span>
                                    <span>{{orderData.actualPayment}}</span>
                                </span>
                            </van-col>
                            <van-col span="24">
                                在綫支付金額:<span>(Online total)</span>
                                <span>
                                    <span style="font-size:12px;">￡</span>
                                    <span>{{orderData.onlinePayment}}</span>
                                </span>
                            </van-col>
                        </van-col> -->
                        <van-col span="24" style="margin-bottom:10px;">共計{{orderData.totalGoodsNum}}件商品</van-col>
                    </van-row>
                </van-col>
                <div class="order_button" v-if="orderData.status != 4">
                    <van-button v-if="orderData.lotteryFlag == '0' && orderData.status == '1'" type="default" @click="jumpTurntableactivity(orderData)" size="normal" style="border-radius:5px;padding:2px 10px;width:70px;height:35px;margin-top: 10px;">抽獎<br>Lottery</van-button>
                    <van-button v-if="orderData.evaluateFlag == '0' && orderData.status == '1' && orderData.hasSalesperson == '1'" type="default" @click="evaluation(orderData)" size="normal" style="border-radius:5px;padding:2px 10px;width:70px;height:35px;margin-top: 10px;">評價<br>Review</van-button>
                    <van-button v-if="orderData.todayFlag == '1' && orderData.status == '1'" type="info" @click="jumpProduct(orderData)" size="normal" style="border-radius:5px;padding:2px 10px;width:80px;height:35px;margin-top: 10px;">補充商品<br>Add-on</van-button>
                    <van-button v-if="orderData.status == '0'" type="info" @click="payForOrder(orderData)" size="normal" style="border-radius:5px;padding:2px 10px;width:70px;height:35px;margin-top: 10px;">待支付<br>Pay</van-button>
                    <van-button v-if="orderData.status == '0'" type="default" @click="cencalOrder(orderData)" size="normal" style="border-radius:5px;padding:2px 10px;width:80px;height:35px;margin-right: 0px;">取消訂單<br>Cancel</van-button>
                    <van-button v-if="orderData.todayFlag == '1' && orderData.status == '1'" type="default" @click="delOrder(orderData)" size="normal" style="border-radius:5px;padding:2px 10px;width:80px;height:35px;margin-top:10px;">删除訂單<br>Deleted</van-button>
                    <span v-if="orderData.status == '6'" style="color:red">訂單支付中...</span>
                </div>
            </van-row>

            <van-dialog v-model:show="batchShow" confirmButtonText="關閉" confirmButtonColor="#000">
                <div class="rankmain">
                    <div class="rankmain-top">
                        <div style="width:50%;">批號</div>
                        <div style="width:50%">數量</div>
                    </div>
                    <div class="rankmain-top-item" id="socllDiv">
                        <div class="rankmain-socll table_tr" v-for="(item,index) in batchList" :key="index">
                            <div style="width: auto !important;color:#B15824;word-wrap: break-word;width:50%;">{{item.batchNumber}}</div>
                            <div style="width: auto !important;color:#FF9F28;width:50%;">{{item.qty}}</div>
                        </div>
                    </div>
                </div>
            </van-dialog>
    </div>
</template>
<script>
import { ImagePreview ,Dialog} from 'vant';
import {cencalOrder,batchNumber,delOrder} from '@/api/order'
export default {
    name:'order',
    props: {
        orderData: {
            type:Object,
            default() {
                return {}
            }
        },
        configure:{
            type:Object,
            default() {
                return {
                    disabled:true
                }
            }
        },
        type:{
            type:Boolean,
            default() {
                return false;
            }
        },
        cencalorder:{}
    },
    data() {
        return {
            batchShow:false,
            batchList:[]
        }
    },
    created() {
    },
    methods: {
        oredrDetail(item) {
            this.$router.push({
                path: '/orderdetail',
                query: {
                    id: item.orderNum,
                    s:item.status,
                    t:item.todayFlag
                }
            })
        },
        changeImg(e,item) {
            if ( e && e.stopPropagation ) {
                e.stopPropagation(); 
            }else {
                window.event.cancelBubble = true; 
            }
            ImagePreview([item.goodsCover]);
        },
        evaluation(item) {
            this.$router.push({
                path: '/review',
                query: {
                    orderNum: item.orderNum
                }
            })
        },
        cencalOrder(item) {
            cencalOrder({orderNum:item.orderNum,subOrderNum:item.recentOrder.subOrderNum ? item.recentOrder.subOrderNum : item.orderNum}).then(res => {
                if(res.code == 200) {
                    this.$emit('cencalorder')
                }
            })
        },
        payForOrder(item) {
            let orderData = JSON.stringify({subOrderNum:item.recentOrder.subOrderNum,paymentMethodIn: item.recentOrder.paymentMethodIn,paymentMethodOut: item.recentOrder.paymentMethodOut})
            this.$router.push({path:'/cart/result',query:{orderData:orderData}})
        },
        jumpProduct(data) {
            localStorage.setItem('orderObject',JSON.stringify(data))
            this.$router.push('/category')
        },
        jumpTurntableactivity(data) {
            let totalGoodsAmount = data.dealAmount - data.vatAmount;
            this.$router.push({path:"/turntable",query:{price:totalGoodsAmount,orderId: data.orderNum,flag:'1'}})
        },
        batchNumber(item) {
            this.batchShow = true;
            batchNumber(item.id).then(res => {
                this.batchList = res.data;
            })
        },
        delOrder(item) {
            Dialog.confirm({
                title: '提示',
                message: '確認刪除該订单嗎？\r\n Are you sure you want to delete ?',
            }).then(() => {
                delOrder({orderNum:item.orderNum}).then(res => {
                    if(res.code == 200) {
                        this.$emit('cencalorder')
                        this.$store.dispatch('getWallet')
                    }
                })
            })
        }
    }
}
</script>
<style scoped lang="scss">
.main{
    overflow: hidden;
    font-size: 18px;
    background: #fff;
    width: 93%;
    margin: 15px auto;
    border-radius: 15px;
} 
.mainrow {
    position: relative;
    box-sizing: border-box;
    padding: 16px 20px;
}
.main-row {
    position: relative;
    box-sizing: border-box;
    padding: 20px 38px;
}
.image {
    position: relative;
    display: inline-block;
    .img {
        display: block;
        width:100%;
        height:100%;
    }
}
.van-card__num {
    text-align: right;
}
.text-right {
    text-align: right;
}
.vatclass {
    font-size: 14px;
    color: #F7A92E;
}
.van-card__title {
    // max-width: 350px;
    // font-size: 18px;
}
.line {
    width: 100%;
    height: 1px;
    border: 1px solid #EEEEEE;
}

.ordercode {
    // min-height: 80px;
    // line-height: 50px;
    font-size: 14px;
}
.orderlable {
    color: #666;
    font-size: 16px;
}
.van-card__price , .van-card__price-integer{
    font-size: 18px;
}
.van-card__price-currency {
    font-size: 16px;
}

.goods-card {
    padding: 16px 12px;
}
.van-card__bottom {
  margin-top: 6px;
}
.van-card__desc {
  margin-top: 6px;
}
.order_button {
    width:100%;
    text-align: right;
}
.order_button button {
    margin-right: 20px;
}
/deep/ .van-ellipsis {
    text-overflow: initial;
    white-space: initial;
}
/deep/ .van-card__desc {
    max-height: initial;
    color: #646566;
    line-height: initial;
}

.rankmain {
  width: 100%;
  text-align: center;
  padding: 40px 40px;
}
.rankmain-top {
  width: 100%;
  height: 49px;
  background: linear-gradient(0deg, #EB8A34 0%, #FA7F34 100%);
  opacity: 0.8;
  border-radius: 24px;
  text-align: center;
  color: #fff;
  font-size: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.rankmain-top-item{
  position: relative;
  width: 100%;
  max-height: 7rem;
  overflow-y: scroll;
}
.rankmain-socll {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.table_tr {
  height: 100px;
  font-size: 0.38rem;
  font-weight: bold;
}
.van-dialog {
    width: 93%;
}
</style>